/* 	Main CSS file for www.ianbyersceramics.com
	@author Tom Byers
	@created 09/02/2011
	
	All hacks & resets courtesy of http://html5boilerplate.com/ (Specific sources listed)
/*

/* Resets */

html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i, dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
  margin:0;
  padding:0;
  border:0;
  outline:0;
  font-size:100%;
  vertical-align:baseline;
  background:transparent;
}
 
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
    display:block;
}
 
nav ul { list-style:none; }
 
blockquote, q { quotes:none; }
 
blockquote:before,
blockquote:after,
q:before,
q:after {
	content:'';
	content:none;
}
 
a {
	margin:0;
	padding:0;
	vertical-align:baseline;
	background:transparent;
}
 
/* tables still need cellspacing="0" in the markup */
table {
	border-collapse:collapse;
	border-spacing:0;
}
 
input,
select { vertical-align:middle; }

/* end of reset */

/* Core element types */

body {
  font-family:Arial, Helvetica, sans-serif;
  font-size:1em; /* 10px */
  background:#fff;
  color:#333;
  text-align:center;
}

a {
	color:#00C;
	text-decoration:none;
}

article a {
    text-decoration: underline;
}

a:hover,
a:focus {
	text-decoration:underline;
}

a:visited {
	color:#339;	
}

.clearfix {
    *zoom: 1;
}

.clearfix:after {
    content: "";
}

p,
article h1,
article h2,
article h3,
article ul {
    text-align: left;
}

p,
article ul,
i.definition {
	line-height:1.5em;
	padding-bottom:1.4em;
}

article ul {
    list-style: disc inside;
}

i.definition {
    display: block;
    text-align: left;
    font-size: 1.2em;
}

ul {
	list-style: none;
}

li {
	clear: both;
}

article li {
    clear: none;
}

em {
	font-style: normal;	
}

h1, h2, h3 {
	font-family: AllerLightRegular, Arial, Helvetica, sans-serif;
	font-weight: normal;
}

h2, h3 { clear: both; }

h2,
article h1,
.heading-level-1 {
	font-size: 1.99em; /* 24px */
	padding-bottom: 0.525em; /* 16.8px */
	padding-top: 0.525em; /* 16.8px */
}

h3,
article h2,
.heading-level-2 {
	font-size: 1.499em; /* 18px */
	padding-bottom: 0.933em; /* 16.8px */
}

h2 a,
h2 a:hover,
h2 a:active,
h2 a:visited {
	color: #333;
}

img.inset-right {
  float: right;
  margin: 0 0 2em 2em;
}

img.inset-right-large {
  margin-right: -191px;
}

img.inset-right-medium {
  margin-right: -151px;
}

img.inset-left {
  float: left;
  margin: 0 2em 2em 0;
}

@media (max-width: 990px) {
    img.inset-right {
        float: none;
        display: block;
        margin: 0 0 2em;
    }
}

@media (max-width: 383px) {
    img.inset-right {
        width: 100%;
    }
}

/* bicubic resizing for non-native sized IMG:
	code.flickr.com/blog/2008/11/12/on-ui-quality-the-little-things-client-side-image-resizing/ */
.ie7 img { -ms-interpolation-mode: bicubic; }

/* Clearfix courtesy j.mp/bestclearfix */
.clearfix:before, .clearfix:after {
  content: "\0020"; display: block; height: 0; visibility: hidden; 
}
 
.clearfix:after { clear: both; }

/* Fix clearfix: blueprintcss.lighthouseapp.com/projects/15318/tickets/5-extra-margin-padding-bottom-of-page */
.clearfix { zoom: 1; }

/* end of Cores */

/* font-face declaration */

@font-face {
    font-family: 'AllerLightRegular';
    src: url('/public/fonts/Aller/aller_lt-webfont.eot?') format('eot'),
         url('/public/fonts/Aller/aller_lt-webfont.woff') format('woff'),
         url('/public/fonts/Aller/aller_lt-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

/* end of font-face declaration */

/* Grids */

#page {
	text-align: left;
	margin: 0px auto;
	max-width: 990px;
    width: 990px\9;
}

/* end of Grids */

/* Page specifics */

/* Home page */

section#content {
    max-width: 580px;
	padding-bottom: 2em;
    text-align: center;
}

@media (max-width: 990px) {
    section#content {
        padding-right: 32px;
        padding-left: 32px;
        margin-right: 0;
    }
}

@media (max-width: 536px) {
    section#content {
        padding-right: 16px;
        padding-left: 16px;
    }
}

.home section#content,
.artwork section#content,
.gallery section#content {
    max-width: 100%;
}

section#content .centrepiece {
    float: left;
	max-width: 600px;
    _width: 600px;
    margin-bottom: 2em;
}

@media (max-width: 990px) {
    section#content .centrepiece {
        float: none;
        display: block;
        width: auto;
        margin: 0 auto 2em;
    }
}

@media (max-width: 536px) {
    section#content .centrepiece {
        margin-right: -32px;
        margin-left: -32px;
    }
}

section#content .centrepiece img {
    width: 100%;
    display: block;
}

section#content .sidepiece {
    float: left;
    text-align: left;
    padding-bottom: 2em;
    border-bottom: solid 1px #aaa;
    margin: 0 32px 2em;
    min-width: 280px;
    _width: 280px;
}

section#content .sidepiece:last-child {
    border-bottom: none;
}

@media (max-width: 990px) {
    section#content .sidepiece {
        float: none;
        display: block;
        border-bottom: solid 1px #aaa;
        margin: 0 0 2em;
        padding-bottom: 2em;
    }

    section#content .sidepiece:last-child {
        margin: 0;
        padding-bottom: 0;
    }
}

section#content .sidepiece h2 {
    font-size: 2.4em;
    padding-top: 0;
}

section#content .sidepiece p {
    padding-bottom: 1em;
}

/* end of Home page */

/* Gallery page */

.gallery .intro {
    font-size: 1.4em;
}

.gallery section ul {
  display: grid;
  grid-template-columns: repeat(3, 200px);
  justify-content: space-around;
  grid-row-gap: 32px;
}

@media (max-width: 860px) {
  .gallery section ul {
    grid-template-columns: repeat(2, 200px);
  }
}

@media (max-width: 660px) {
  .gallery section ul {
    grid-template-columns: repeat(1, 200px);
  }
}

.gallery section li {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

@media (max-width: 840px) {
  .gallery intro {
    margin-left: 32px;
    margin-right: 32px;
  }
}

.gallery section li a,
.gallery section li a img {
	display:block;
}

.gallery section li a {
	padding: 16px 0px 16px;
}

.gallery section li a img {
	padding-bottom: 16px;
	margin: 0 auto;
}

/* end of Gallery page */

/* Page header */

#masthead {
	padding: 1.4em 0em;
    margin-bottom: 1em;
    border-bottom: solid 1px #aaa;
    overflow: hidden;
    *zoom: 1;
}

@media (max-width: 990px) {
    #masthead {
        padding-left: 32px;
        padding-right: 32px;
    }
}

@media (max-width: 536px) {
    #masthead {
        padding-left: 16px;
        padding-right: 16px;
    }
}

.home #masthead {
    margin-bottom: 1.8em;
}

@media (max-width: 536px) {
    .home #masthead {
        margin-bottom: 0;
    }
}

#masthead a {
    color: #333;
    font-size: 2em;
    padding-top: 0.5em;
    display: inline-block;
    *display: inline;
    *zoom: 1;
}

#masthead .logo {
	font-size: 3em; /* 64px */
    float:left;
	text-transform: uppercase;
	margin-left: 0px;
	padding: 0;
}

#masthead .logo a {
    color: #333;
    display: inline;
    font-size: 1em;
}

.page-header {
    text-align: right;
    padding-bottom: 1em;
    border-bottom: solid 1px #aaa;
	margin-bottom: 1.6em;
}

@media (max-width: 990px) {
    .page-header {
        text-align: left;
        padding-left: 32px;
        padding-right: 32px;
    }
}

@media (max-width: 536px) {
    .page-header {
        padding-left: 16px;
        padding-right: 16px;
    }
}

.page-header h1 {
    font-size: 2em;
    float: left;
}

.breadcrumb {
    padding-top: 0.95em;
}

@media (max-width: 990px) {
    .breadcrumb {
        clear: left;
        line-height: 2em;
    }
}

.breadcrumb li {
	display: inline;
	padding-right:6px;
}

.breadcrumb li a {
	padding-right: 24px;
	background: transparent url("/public/images/breadcrumb-arrow.png") right center no-repeat;
}

/* end of Page header */

/* Footer */

#footer {
    font-size:1.2em;
	padding: 1em 0;
	margin: 0 auto;
	border-top: solid 1px #aaa;
	clear: both;
}

#footer a {
	color: #333;	
}

#footer li {
	text-align: right;
	display: inline;
	padding-right: 60px;
}

@media (max-width: 990px) {
    #footer {
        padding-left: 32px;
    }

    #footer li {
        padding-right: 32px;
    }
}

@media (max-width: 536px) {
    #footer {
        padding-left: 16px;
    }

    #footer li {
        display: block;
        text-align: left;
        margin-bottom: 1em;
    }
}

#footer li em {
    text-decoration: underline;
}

/* end of Footer */

/* end of Page area specifics */

/* Definition lists */

dl {
    text-align: left;
    overflow: hidden;
    margin-bottom: 1em;
}

dl dt,
dl dd {
    line-height: 1.5;
}

dl dt {
    float: left;
    width: 30%;
}

dl dd {
    float: right;
    width: 70%;
}

dt {
  clear: right;
}

@media (max-width: 536px) {
    dl dt,
    dl dd {
        float: none;
        width: auto;
    }

    dl dt {
        font-weight: bold;
    }

    dl dd {
        margin-bottom: 1em;
    }
}

/* end of Lists */

/* Misc */

section#content article {
	border-top: solid 1px #aaa;
	margin-top: 1.4em;
}

section#content .intro {
    border-top: none;
    margin-top: 0;
}

.info {
    text-align: left;
    clear: both;
    width: 100%;
    margin-bottom: 0;
    padding-top: 2em;
    border-top: solid 1px #aaa;
}

.info a {
    text-decoration: underline;
}

.info dt,
.info dd {
	line-height:2em;
}

.info dt {
    width: 15%;
}

.info dd {
    width: 85%;
    *width: 84%;
}

@media (max-width: 536px) {
    .info dt,
    .info dd {
        width: 50%;
    }
}

.artworkImages {
	float:left;
	margin:0 0 2em 0;
}

.artworkImages a {
    text-decoration: underline;
}

.artworkImages .mainImage,
.artworkImages img {
    float: left;
}

.artworkImages .mainImage {
    max-width: 600px;
    _width: 600px;
    text-align: right;
}

@media (max-width: 990px) {
    .artworkImages .mainImage {
        margin: 0 auto;
    }
}

@media (max-width: 536px) {
    .artworkImages .mainImage {
        width: auto;
        margin: 0 -16px;
    }

    .artworkImages .mainImage a {
        padding: 0 16px;
    }
}

.artworkImages .mainImage img {
    display: block;
    width: 100%;
    margin-bottom: 1em;
}

.additionalImages {
	float: left;
  width: 358px;
  margin-left: 32px;
}

@supports (display: grid) {
  .additionalImages {
    display: grid;
    grid-template-columns: repeat(auto-fill, 90px);
    column-gap: 32px;
    row-gap: 32px;
  }
}

@media (max-width: 990px) {
    .additionalImages {
        float: none;
        clear: left;
        width: 100%;
        margin-left: 0;
        padding-top: 32px;
    }

    @supports (display: grid) {
      .additionalImages {
        justify-content: center;
      }
    }
}

.additionalImages li {
    float: left;
    clear: none;
    margin: 0 32px 32px 0;
    width: 90px;
}

.additionalImages li.selected {
    margin-top: -5px;
    margin-right: 27px;
    margin-left: -5px;
    margin-bottom: 27px;
    padding: 4px;
    border: solid 1px #aaa;
}

@supports (display: grid) {
  .additionalImages li {
    margin: 0;
  }

  .additionalImages li.selected {
    margin-bottom: -5px;
  }
}

.additionalImages a {
    display: block;
}

.additionalImages img {
    float: none;
    display: block;
    margin: 0 auto;
}

/* slideshows */

.slideshow {
    text-align: center;
    margin-bottom: 2em;
}

.slideshow img {
    display: none;
    margin: 0 auto;
    padding-bottom: 12px;
}

.no-js .slideshow img,
.slideshow img.initial {
    display: block;
}

.portrait .initial {
    max-width: 383px;
}

.landscape img {
    max-width: 510px;
}

.slideshow .controls {
    margin: 0 auto;
}

.landscape .controls {
    width: 510px;
}

.portrait .controls {
    width: 383px;
}

.slideshow .controls li {
    float: left;
    clear: none;
    margin: 20px 15px 0 0;
    list-style: none;
}

.landscape .controls li {
    width: 90px;
    height: 68px;
}

.portrait .controls li {
    height: 90px;
    width: 68px;
    margin-right: 37px;
}

.slideshow .controls li.last {
    margin-right: 0;
}

@media (max-width: 351px) {
    .portrait img {
        width: 100%;
    }
}

@media (max-width: 588px) {
    .landscape img {
        width: 100%;
    }

    .slideshow {
        margin: 0 -16px 2em;
    }

    .slideshow .controls {
        margin: 0 16px;
        width: auto;
    }
    
    .portrait .controls li.last {
        margin-right: 37px;
    }
    
    .landscape .controls li.last {
        margin-right: 15px;
    }
}

.slideshow .controls a {
    display: block;
}

.slideshow .controls a.active {
    border: solid 1px #aaa;
    padding: 4px;
    margin: -5px;
}

.slideshow .controls img {
    padding-bottom: 0;
    display: block;
    margin: 0 auto;
}

/* Videos */

figure { 
    text-align: left;
}

.video {
    margin-bottom: 2em;
}

.video video {
    width: 100%;
}

.video-copyright {
    text-align: right;
    font-size: 0.75em;
}

.video .video-copyright {
    padding-bottom: 0;
}

.no-js .video-transcript {
    display: block;
}

.video-transcript {
    display: none;
}

.media-player-wrapper {
    width: 100%;
}

@media (max-width: 620px) {
    .media-player-wrapper iframe {
        width: 100% !important;
    }
}
